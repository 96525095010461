import { useReducer, useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/es';
import Config from '../config';
import transactionsService from '../services/transactions';
import useTransferPrices from './useTransferPrices';
import useAmounts from './useAmounts';
import { formatMoney, isValidEmail, decimalRound, USD_RATE_ROUTES } from '../utils';

const AMOUNT_CHANGE = 'useTransfer/AMOUNT_CHANGE';
const RECEIVE_AMOUNT_CHANGE = 'useTransfer/RECEIVE_AMOUNT_CHANGE';
const SEND_CURRENCY_CHANGE = 'useTransfer/SEND_CURRENCY_CHANGE';
const COUNTRY_CHANGE = 'useTransfer/COUNTRY_CHANGE';
const IS_READY = 'useTransfer/IS_READY';
const AMOUNTS_REFRESH = 'useTransfer/AMOUNTS_REFRESH';
const FIELD_FORM_CHANGE = 'useTransfer/FIELD_FORM_CHANGE';
const RULES_UPDATE = 'useTransfer/RULE_UPDATE';
const RESET_FORM = 'useTransfer/RESET_FORM';
const VALIDATE_FORM = 'useTransfer/VALIDATE_FORM';
const RESET_TRANSFER_FORM = 'useTransfer/RESET_TRANSFER_FORM';
const COUPON_DATA = 'useTransfer/COUPON_DATA';
const OPERATION_WITH_COUPON = 'useTransfer/OPERATION_WITH_COUPON';
const CONFIRMATION = 'useTransfer/CONFIRMATION';
const CALCULATE_FIXED_COSTS = 'useTransfer/CALCULATE_FIXED_COSTS';
const RESET_FLAG_SCREEN_TRANSACTION = 'useTranfer/RESET_FLAG_SCREEN_TRANSACTION';
const LOADING_DATA_V2_TRANSFER = 'useTransfer/LOADING_DATA_V2_TRANSFER'
const CHECK_FLAG_SCREEN_TRANSACTION = 'useTransfer/CHECK_FLAG_SCREEN_TRANSACTION'
const CHECK_PAYMENT_TYPE = 'useTransfer/CHECK_PAYMENT_TYPE'

const reducer = (state, action) => {
  const { decimalAmounts, countryByCurrencyCode } = state;

  switch (action.type) {
    case RESET_TRANSFER_FORM: {
      const { country, isAddFrequentAccount } = state;
      const { rules } = action.payload;
      return {
        ...state,
        form: {
          ...createSchema(country, rules, isAddFrequentAccount),
          errors: {},
          isValidForm: false,
        },
        isValidScreenTransaction: false
      };
    }
    case IS_READY: {
      const { minimumAmount, sendCurrency, sendCurrencyDecimals } = state;

      return {
        ...state,
        isReady: true,
        amountLabel: formatMoney(
          0,
          sendCurrency,
          sendCurrencyDecimals,
        ),
        sendCurrency,
        minimumAmount,
      };
    }
    case RULES_UPDATE: {
      const { country, isAddFrequentAccount } = state;
      const { rules } = action.payload;

      return {
        ...state,
        rule: rules[country],
        form: createSchema(country, rules, isAddFrequentAccount),
        isRules: true,
      };
    }
    case AMOUNT_CHANGE: {
      const { amount, prices, balances } = action.payload;
      const {
        country,
        receiveCurrency,
        minimumAmount,
        rule: {
          currency_decimals,
        },
        sendCurrencyDecimals,
        is_allowed_transaction,
        paymentType,
        countryUser,
      } = state;
      let {
        sendCurrency,
        usdRateRoute
      } = state;

      sendCurrency = sendCurrency.toLowerCase();
      const prices_country = prices.fiat[country] || {};
      let fixedCost = 0

      if (paymentType && paymentType.is_fast) {
        fixedCost = paymentType.fixed_cost
      } else {
        fixedCost = prices_country[`${sendCurrency}_is_apply_fixed_cost_in_transfers`]
          ? parseFloat(prices_country[`${sendCurrency}_fixed_cost_transfer`])
          : 0;
      }

      const receiveAmount = (amount * prices_country[`${sendCurrency}_sell`]) - fixedCost;
      const validReceiveAmount = decimalAmounts(receiveAmount, countryByCurrencyCode(receiveCurrency).iso_code);

      const TR = (amount > 0 && receiveAmount > 0 && amount >= minimumAmount)
        ? (receiveAmount / amount)
        : prices_country[`${sendCurrency}_sell`];

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      return {
        ...state,
        rate: TR,
        isReceiveStatic: false,
        amount,
        amountLabel: formatMoney(
          amount,
          sendCurrency,
          sendCurrencyDecimals,
        ),
        receiveAmount,
        receiveAmountLabel: formatMoney(
          receiveAmount,
          receiveCurrency,
          currency_decimals,
        ),
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency] && validReceiveAmount > 0 && is_allowed_transaction,
        usdRateRoute,
        priceValidDate: prices?.valid_until,
      };
    }
    case RECEIVE_AMOUNT_CHANGE: {
      const {
        receiveAmount,
        prices,
        balances,
      } = action.payload;

      const {
        country,
        receiveCurrency,
        minimumAmount,
        rule: {
          currency_decimals,
        },
        sendCurrencyDecimals,
        is_allowed_transaction,
        paymentType,
        countryUser
      } = state;
      let {
        sendCurrency,
        usdRateRoute,
      } = state;

      let fixedCost = 0
      sendCurrency = sendCurrency.toLowerCase();
      const prices_country = prices.fiat[country] || {};

      if (paymentType && paymentType.is_fast) {
        fixedCost = paymentType.fixed_cost
      } else {
        fixedCost = prices_country[`${sendCurrency}_is_apply_fixed_cost_in_transfers`]
          ? parseFloat(prices_country[`${sendCurrency}_fixed_cost_transfer`])
          : 0;
      }

      const amountCost =
        fixedCost / prices_country[`${sendCurrency}_sell`];
      const validReceiveAmount = decimalAmounts(
        receiveAmount,
        countryByCurrencyCode(receiveCurrency).iso_code,
      );

      let amount = receiveAmount !== 0 ? (receiveAmount / prices_country[`${sendCurrency}_sell`]) + amountCost : 0;
      const truncatedAmount = decimalAmounts(amount, countryByCurrencyCode(sendCurrency).iso_code);

      const TR = (truncatedAmount > 0 && receiveAmount > 0 && truncatedAmount >= minimumAmount)
        ? (receiveAmount / truncatedAmount)
        : prices_country[`${sendCurrency}_sell`];

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      return {
        ...state,
        amount: decimalRound(amount, 9),
        isReceiveStatic: true,
        rate: TR,
        amountLabel: formatMoney(
          amount,
          sendCurrency,
          sendCurrencyDecimals,
        ),
        receiveAmount,
        receiveAmountLabel: formatMoney(
          receiveAmount,
          receiveCurrency,
          currency_decimals,
        ),
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency] && validReceiveAmount > 0 && is_allowed_transaction,
        usdRateRoute,
        priceValidDate: prices?.valid_until,
      };
    }
    case SEND_CURRENCY_CHANGE: {
      const {
        sendCurrency,
        prices,
        balances,
      } = action.payload;
      const {
        rule,
        receiveCurrency,
        country,
        receiveAmount,
        is_allowed_transaction,
        countryUser
      } = state;
      let {
        usdRateRoute,
        sendCurrencyDecimals,
      } = state;

      const prices_country = prices.fiat[country] || {};
      const fixedCost = prices_country[`${sendCurrency}_is_apply_fixed_cost_in_transfers`]
        ? parseFloat(prices_country[`${sendCurrency}_fixed_cost_transfer`])
        : 0;
      const amountCost = fixedCost / prices_country[`${sendCurrency}_sell`];

      const minimumAmount = decimalAmounts(
        prices_country[`${sendCurrency}_transfer_min_amount`],
        country,
        true
      );

      let amount = receiveAmount !== 0 ? (receiveAmount / prices_country[`${sendCurrency}_sell`]) + amountCost : 0;
      const truncatedAmount = decimalAmounts(amount, countryByCurrencyCode(sendCurrency).iso_code);

      const TR = (truncatedAmount > 0 && receiveAmount > 0 && truncatedAmount >= minimumAmount)
        ? (receiveAmount / truncatedAmount)
        : prices_country[`${sendCurrency}_sell`];

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, rule.currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      sendCurrencyDecimals = countryByCurrencyCode(sendCurrency).currency_decimals;

      return {
        ...state,
        amount: decimalRound(amount, 9),
        amountLabel: formatMoney(
          amount,
          sendCurrency,
          sendCurrencyDecimals,
        ),
        sendCurrency,
        sendCurrencyDecimals,
        rate: TR,
        usdRateRoute,
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency] && receiveAmount > 0 && is_allowed_transaction,
        fixedCost,
        minimumAmount,
      };
    }
    case COUNTRY_CHANGE: {
      const {
        country,
        country_flag,
        country_currency_name,
        prices,
        DynamicForm,
        rules,
        balances,
      } = action.payload;
      const {
        amount,
        isAddFrequentAccount,
        is_allowed_transaction,
        countryUser,
      } = state;
      let {
        sendCurrency,
        usdRateRoute,
      } = state;
      sendCurrency = sendCurrency.toLowerCase();
      const rule = rules[country];

      let paymentListTypes = [];
      let paymentType = null;

      if (rules[country].hasOwnProperty('payouts_types')) {
        paymentListTypes = rules[country].payouts_types
          .filter(item => item.is_active)
          .map(item => ({
            ...item,
            label: item.primary_label,
            value: item.id,
          }));

        paymentType = paymentListTypes[0]
      }

      const prices_country = prices.fiat[country] || {};
      const fixedCost = prices_country[`${sendCurrency}_is_apply_fixed_cost_in_transfers`]
        ? parseFloat(prices_country[`${sendCurrency}_fixed_cost_transfer`])
        : 0;
      const receiveAmount = (amount * prices_country[`${sendCurrency}_sell`]) - fixedCost;
      const receiveCurrency = rule.currency_code;
      const validReceiveAmount = decimalAmounts(
        receiveAmount,
        countryByCurrencyCode(receiveCurrency).iso_code,
      );

      const minimumAmount = decimalAmounts(
        prices_country[`${sendCurrency}_transfer_min_amount`],
        country,
        true
      );
      const TR = (amount > 0 && receiveAmount > 0 && amount >= minimumAmount)
        ? (receiveAmount / amount)
        : prices_country[`${sendCurrency}_sell`];

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, rule.currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      return {
        ...state,
        country,
        isWires: country.toLowerCase().indexOf("wire") != -1,
        country_flag,
        country_currency_name,
        isReceiveStatic: false,
        rate: TR,
        receiveCurrency,
        receiveAmount,
        minimumAmount,
        receiveAmountLabel: formatMoney(
          receiveAmount,
          receiveCurrency,
          rule.currency_decimals,
        ),
        rule,
        form: createSchema(country, rules, isAddFrequentAccount),
        DynamicForm,
        messageEstimatedTime: prices.message_times_withdrawal && prices.message_times_withdrawal[country.toLowerCase()],
        day: moment()
          .add(prices.days[country.toLowerCase()], 'days')
          .format('LL')
          .split(' de 20')[0],
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency] && validReceiveAmount > 0 && is_allowed_transaction,
        fixedCost,
        usdRateRoute,
        priceValidDate: prices?.valid_until,
        paymentListTypes,
        paymentType,
      };
    }
    case AMOUNTS_REFRESH: {
      const {
        prices,
        balances,
      } = action.payload;
      const {
        country,
        amount,
        receiveCurrency,
        sendCurrencyDecimals,
        is_allowed_transaction,
        isReceiveStatic,
        receiveAmount: destAmount,
        rule: {
          currency_decimals,
        },
        paymentType,
        countryUser,
      } = state;
      let {
        sendCurrency,
        usdRateRoute,
      } = state;
      sendCurrency = sendCurrency.toLowerCase();
      const prices_country = prices.fiat[country] || {};
      const fixedCostBase = prices_country[`${sendCurrency}_is_apply_fixed_cost_in_transfers`]
        ? parseFloat(prices_country[`${sendCurrency}_fixed_cost_transfer`])
        : 0;
      const amountCost = fixedCostBase / prices_country[`${sendCurrency}_sell`];
      let receiveAmount = destAmount;
      let sentAmount = amount;

      if (!paymentType?.is_fast) {
        if (isReceiveStatic) {
          sentAmount = receiveAmount !== 0 ? (receiveAmount / prices_country[`${sendCurrency}_sell`]) + amountCost : 0;
        } else {
          receiveAmount = (sentAmount * prices_country[`${sendCurrency}_sell`]) - fixedCostBase;
        }
      }

      const minimumAmount = decimalAmounts(
        prices_country[`${sendCurrency}_transfer_min_amount`],
        country,
        true
      );

      const validReceiveAmount = decimalAmounts(receiveAmount, countryByCurrencyCode(receiveCurrency).iso_code);

      const TR = (sentAmount > 0 && receiveAmount > 0 && sentAmount >= minimumAmount)
        ? (receiveAmount / sentAmount)
        : prices_country[`${sendCurrency}_sell`];

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      return {
        ...state,
        amount: sentAmount,
        receiveAmount,
        minimumAmount,
        rate: TR,
        amountLabel: formatMoney(
          sentAmount,
          sendCurrency,
          sendCurrencyDecimals,
        ),
        receiveAmountLabel: formatMoney(
          receiveAmount,
          receiveCurrency,
          currency_decimals,
        ),
        messageEstimatedTime: prices.message_times_withdrawal && prices.message_times_withdrawal[country.toLowerCase()],
        day: moment()
          .add(prices.days[country.toLowerCase()], 'days')
          .format('LL')
          .split(' de 20')[0],
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency] && validReceiveAmount > 0 && is_allowed_transaction,
        fixedCost: fixedCostBase,
        usdRateRoute,
        priceValidDate: prices?.valid_until,
      };
    }
    case FIELD_FORM_CHANGE: {

      const { name, value } = action.payload;
      const { form, dataScreenTransactionFirst, paymentMobile, rule: { rules }, isAddFrequentAccount } = state;
      let { notFirstRender } = state;
      const {
        values,
        validationSchema,
        validationSchemaGlobal,
        touched,
      } = form;
      let {
        errors
      } = form;

      let checkPaymentMobile = paymentMobile
      let isValidForm = true;

      for (let v in values) {
        if (values.hasOwnProperty(v)) {
          touched[v] = false;
        }
      }

      if (
        validationSchema.hasOwnProperty('beneficiary_type') &&
        name === 'beneficiary_type' &&
        values[name] !== value &&
        isAddFrequentAccount
      ) {
        if (!notFirstRender) {
          notFirstRender = true;
        } else {
          values['first_name'] = '';
          values['last_name'] = '';
          values['company_name'] = '';
        }
      }

      if (!values.hasOwnProperty(name)) {
        return {
          ...state,
        };
      }

      values[name] = value;
      touched[name] = true;

      try {
        validationSchema[name].validateSync(value);
        if (errors[name]) {
          delete errors[name];
        }
      } catch (error) {
        errors[name] = error.message;
      }

      try {
        validationSchemaGlobal.validateSync(values);
        errors = {};
      } catch (error) {
        errors[error.path] = error.message;
        isValidForm = false;
      }

      const validateScreenTransaction = () => {
        {
          let validDataInitialScreenTransaction = dataScreenTransactionFirst.filter(f => {
            if (values['beneficiary_type'] === 'Individual')
              return f !== 'company_name'
            else
              return f !== 'first_name' || f !== 'last_name'
          }).map(
            (item) => {
              if (values[item] !== '') return true;
              else return false;
            },
          );
          const isCompletedForm = (currentValue) => currentValue === true;

          let isCompleted = validDataInitialScreenTransaction.every(isCompletedForm);

          if (isCompleted) {
            let responseValidateEmail = isValidEmail(values.email);
            if (responseValidateEmail) {
              return responseValidateEmail;

            }
          }
        }
        return false;
      }

      let responseValidate = validateScreenTransaction();

      if (values.transfer_type && values.transfer_type === "Transferencia bancaria") {
        checkPaymentMobile = true;
      }

      if (values.transfer_type && values.transfer_type === "Pago movil" && checkPaymentMobile) {
        const banks = rules.find(section => section.label.toLowerCase() === "Datos bancarios".toLocaleLowerCase())
          .fields.find(field => field.name.toLocaleLowerCase() === "bank_id_select".toLocaleLowerCase())
          .options.filter(option => {
            return option.visible?.value.find(e => e === values.transfer_type);
          })

        let bank = banks.find(item => item.value === values.bank_id);
        bank = bank ? bank : banks[0];

        checkPaymentMobile = false;

        return {
          ...state,
          form: {
            ...form,
            errors,
            touched,
            isValidForm,
            values: {
              ...values,
              bank_id: bank.value,
              bank_id_select: bank.label,
            }
          },
          paymentMobile: checkPaymentMobile,
          isValidScreenTransaction: responseValidate,
        };
      }

      return {
        ...state,
        notFirstRender,
        form: {
          ...form,
          values,
          errors,
          touched,
          isValidForm,
        },
        paymentMobile: checkPaymentMobile,
        isValidScreenTransaction: responseValidate
      };
    }
    case RESET_FORM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case VALIDATE_FORM: {
      const { keys_ignore } = action.payload;
      const { form } = state;
      const { values, validationSchemaGlobal } = form;
      let { errors } = form;

      let isValidForm = true;

      const valuesCopy = { ...values };

      if (keys_ignore) {
        keys_ignore.forEach(key => {
          valuesCopy[key] = 'value for skip validate';
        });
      }

      try {
        validationSchemaGlobal.validateSync(valuesCopy);
      } catch (error) {
        errors[error.path] = error.message;
        isValidForm = false;
      }

      return {
        ...state,
        form: {
          ...form,
          isValidForm,
          errors,
        },
      };
    }
    case COUPON_DATA: {
      return {
        ...state,
        coupon: action.payload,
      };
    }
    case OPERATION_WITH_COUPON: {
      const {
        receiveAmount,
        coupon: { amountCoupon, bonustype },
        receiveCurrency,
        country,
        rule,
        amount,
      } = state;

      const { prices, default_currency } = action.payload;

      if (bonustype === 'amount') {
        const prices_country = prices.fiat[country];
        const receiveAmountCoupon =
          amountCoupon *
          prices_country[`${default_currency.toLowerCase()}_sell`];

        const totalReceiveCouponAmount = receiveAmount + receiveAmountCoupon;

        return {
          ...state,
          receiveAmountCoupon,
          receiveAmountLabel: formatMoney(
            totalReceiveCouponAmount,
            receiveCurrency,
            rule.currency_decimals,
          ),
          receiveAmount: totalReceiveCouponAmount,
          priceValidDate: prices?.valid_until,
        };
      } else {
        const prices_country = prices.fiat[country];

        const receiveAmountCoupon =
          ((amount * amountCoupon) / 100) *
          prices_country[`${default_currency.toLowerCase()}_sell`];

        const totalReceiveCouponAmount = receiveAmount + receiveAmountCoupon;

        return {
          ...state,
          receiveAmountCoupon,
          receiveAmountLabel: formatMoney(
            totalReceiveCouponAmount,
            receiveCurrency,
            rule.currency_decimals,
          ),
          receiveAmount: totalReceiveCouponAmount,
          priceValidDate: prices?.valid_until,
        };
      }
    }
    case CONFIRMATION: {
      const {
        amount,
        receiveAmount,
        receiveCurrency,
        fixedCost: fixedCostBase,
        calculatedFixedCost,
        rule: {
          currency_decimals,
        },
        countryUser,
      } = state;
      let {
        sendCurrency,
        usdRateRoute,
      } = state;
      const {
        status,
        prices,
      } = action.payload;

      sendCurrency = sendCurrency.toLowerCase();
      const TR = (decimalAmounts(receiveAmount, countryByCurrencyCode(receiveCurrency).iso_code) / amount);

      if (USD_RATE_ROUTES.includes(countryUser.toUpperCase())) {
        const us_price = prices.fiat['us'][`${sendCurrency}_spot`];

        usdRateRoute = [
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney(1 / us_price, sendCurrency, 2)} ${sendCurrency.toUpperCase()}`,
          },
          {
            oneCurrencyToEqualsLabel: `1 USDC ≈ `,
            rateWithCurrencyNameLabel: `${formatMoney((TR / us_price), receiveCurrency, currency_decimals)} ${receiveCurrency.toUpperCase()}`,
          },
        ];
      }

      return {
        ...state,
        rate: TR,
        usdRateRoute,
        fixedCost: fixedCostBase,
        calculatedFixedCost: {
          ...calculatedFixedCost,
          costoTransaccion: fixedCostBase,
        },
        isConfirmation: status,
        priceValidDate: prices?.valid_until,
      }
    }
    case CALCULATE_FIXED_COSTS: {
      const {
        costoComision,
        costoGMF,
        costoIVA,
        costoTransaccion,
        is_allowed_transaction,
        balances,
      } = action.payload;
      const {
        minimumAmount,
        amount,
        sendCurrency,
      } = state;

      return {
        ...state,
        isValid: amount >= minimumAmount && amount <= balances[sendCurrency.toLowerCase()] && is_allowed_transaction,
        is_allowed_transaction,
        calculatedFixedCost: {
          costoComision,
          costoGMF,
          costoIVA,
          costoTransaccion,
        }
      }
    }
    case RESET_FLAG_SCREEN_TRANSACTION: {
      return {
        ...state,
        dataScreenTransactionFirst: [],
        isValidScreenTransaction: false
      };
    }
    case CHECK_FLAG_SCREEN_TRANSACTION: {
      const { form, dataScreenTransactionFirst } = state;
      const { values } = form;

      const validateScreenTransaction = () => {
        {
          let validDataInitialScreenTransaction = dataScreenTransactionFirst.map(
            (item) => {
              if (values[item] !== '') return true;
              return false;
            },
          );
          const isCompletedForm = (currentValue) => currentValue === true;

          let isCompleted = validDataInitialScreenTransaction.every(isCompletedForm);

          if (isCompleted) {
            let responseValidateEmail = isValidEmail(values.email);
            if (responseValidateEmail) {
              return responseValidateEmail;

            }
          }
        }
        return false
      }
      let responseValidate = validateScreenTransaction()

      return {
        ...state,
        isValidScreenTransaction: responseValidate
      };
    }
    case LOADING_DATA_V2_TRANSFER: {
      const { name } = action.payload
      const { dataScreenTransactionFirst } = state;

      let isAvailable = dataScreenTransactionFirst.indexOf(name);

      if (isAvailable === -1) {
        return {
          ...state,
          dataScreenTransactionFirst: state.dataScreenTransactionFirst.concat(name),
        };
      }

      return {
        ...state,
      };
    }
    case CHECK_PAYMENT_TYPE: {
      const data = state.paymentListTypes.find(item => item.value === action.payload.id);

      return {
        ...state,
        paymentType: data,
        messageEstimatedTime: data && data.estimated_time,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const useTransfer = (props = {}) => {
  const { useUser, useRules } = Config.getInstance().getConfiguration();
  const { rules, vitaCountries, fiatCurrencies } = useRules();
  const { transferRules } = rules;
  const { prices, isReadyPrices, errorFetch } = useTransferPrices();
  const { user, updateProfile, userIsReady } = useUser();
  const { decimalAmounts, countryByCurrencyCode } = useAmounts();
  const {
    default_currency,
    handle_default_fiat_currency,
    balances,
    headers,
    iso_code_country,
  } = user;
  const { iso_code, currency_code, currency_name, flag_url } = vitaCountries.length > 0
    ? vitaCountries[0]
    : { iso_code: 'AR', currency_code: 'ARS' };
  const sendCurrency = handle_default_fiat_currency?.toLowerCase() || 'clp';
  const transfer_min_amount = prices?.fiat[iso_code.toLowerCase()][`${sendCurrency}_transfer_min_amount`] || 0;

  const [state, dispatch] = useReducer(reducer, {
    amount: 0,
    amountLabel: formatMoney(0, handle_default_fiat_currency),
    receiveAmount: 0,
    receiveAmountLabel: formatMoney(0, currency_code),
    isReady: false,
    receiveCurrency: currency_code,
    country: iso_code.toLowerCase(),
    country_flag: flag_url,
    country_currency_name: currency_name,
    rule: [],
    sendCurrency,
    sendCurrencyDecimals: countryByCurrencyCode(handle_default_fiat_currency).currency_decimals,
    minimumAmount: decimalAmounts(transfer_min_amount, iso_code, true),
    isValid: false,
    form: {},
    isRules: false,
    day: '',
    rate: 0,
    isAddFrequentAccount: props.hasOwnProperty('isAddFrequentAccount')
      ? props.isAddFrequentAccount
      : false,
    coupon: false,
    receiveAmountCouponLabel: formatMoney(0, currency_code),
    totalReceiveCouponAmountLabel: formatMoney(0, currency_code),
    isConfirmation: false,
    calculatedFixedCost: {
      costoComision: 0,
      costoGMF: 0,
      costoIVA: 0,
      costoTransaccion: 0,
    },
    is_allowed_transaction: true,
    isValidScreenTransaction: false,
    dataScreenTransactionFirst: [],
    isReceiveStatic: false,
    paymentMobile: false,
    usdRateRoute: [],
    messageEstimatedTime: '',
    decimalAmounts,
    countryByCurrencyCode,
    priceValidDate: prices?.valid_until,
    paymentType: null,
    paymentListTypes: [],
    countryUser: iso_code_country,
  });

  // const {
  //   dataCoupon,
  //   setDataCoupon,
  //   cancelCouponAction,
  //   onPressActive,
  //   onPressInvalid,
  //   hook,
  //   flag,
  //   setFlag,
  //   clearDataCoupon,
  // } = useCouponData();

  // useEffect(() => {
  //   if (dataCoupon.name !== '') {
  //     dispatch({
  //       type: COUPON_DATA,
  //       payload: dataCoupon,
  //     });
  //   } else {
  //     resetCouponData();
  //   }
  // }, [dataCoupon.name]);

  // const resetCouponData = useCallback(() => {
  //   dispatch({
  //     type: COUPON_DATA,
  //     payload: false,
  //   });
  // }, [dispatch]);

  let _isRulesFetch = false;

  useEffect(() => {
    if (isReadyPrices && userIsReady) {
      dispatch({ type: IS_READY, payload: { user } });
    }
  }, [isReadyPrices, user, userIsReady]);

  useEffect(() => {
    if (balances && prices.fiat) {
      dispatch({
        type: AMOUNTS_REFRESH,
        payload: {
          prices,
          balances,
        },
      });
    }
  }, [prices, balances]);

  useEffect(() => {
    if (!_isRulesFetch && rules.transferRules.rules.hasOwnProperty('cl')) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      _isRulesFetch = true;
      dispatch({
        type: RULES_UPDATE,
        payload: {
          rules: rules.transferRules.rules,
        },
      });
    }
  }, [rules]);

  const changeAmount = useCallback(
    (amount) => {
      dispatch({
        type: AMOUNT_CHANGE,
        payload: {
          amount,
          prices,
          balances,
        },
      });
    },
    [dispatch, prices, balances],
  );

  const changeReceiveAmount = useCallback(
    (receiveAmount) => {
      dispatch({
        type: RECEIVE_AMOUNT_CHANGE,
        payload: {
          receiveAmount,
          prices,
          balances,
        },
      });
    },
    [dispatch, prices, balances],
  );

  const changeSendCurrency = useCallback((currency) => {
    dispatch({
      type: SEND_CURRENCY_CHANGE,
      payload: {
        sendCurrency: currency.toLowerCase(),
        prices,
        balances,
      }
    });
  }, [
    prices,
    balances,
  ]);

  const changeCountry = useCallback(
    (country) => {
      resetDataInitialScreenTransaction();
      dispatch({
        type: COUNTRY_CHANGE,
        payload: {
          country: country.iso_code.toLowerCase(),
          country_flag: country.flag_url,
          country_currency_name: country.currency_name,
          prices,
          rules: transferRules.rules,
          balances,
          default_currency,
        },
      });
    },
    [dispatch, prices, transferRules, default_currency, balances],
  );

  const handleMaxAmount = useCallback(() => {
    const {sendCurrency} = state;

    changeAmount(
      Number(
        decimalAmounts(
          balances[sendCurrency.toLowerCase()],
          countryByCurrencyCode(sendCurrency).iso_code,
        ),
      ),
    );
  }, [
    state,
    changeAmount,
    balances,
    countryByCurrencyCode,
    decimalAmounts,
  ]);

  const loadingDataInitialScreenTransaction = useMemo(
    () => {
      return (name) => {
        dispatch({
          type: LOADING_DATA_V2_TRANSFER,
          payload: { name }
        })
      }
    }, [dispatch])

  const resetDataInitialScreenTransaction = useCallback(() => {
    dispatch({
      type: RESET_FLAG_SCREEN_TRANSACTION,
    });
  }, [dispatch])

  const checkFlagScreenTransaction = () => {
    dispatch({
      type: CHECK_FLAG_SCREEN_TRANSACTION,
    });
  }

  const handleChange = useCallback(
    (name) => {
      return (value) => {
        if (name === 'pix_key_type') {
          const options = ['code_cnpj', 'code_cpf', 'email', 'phone_number', 'random_key']

          options.forEach((key) => {
            dispatch({
              type: FIELD_FORM_CHANGE,
              payload: { name: `account_bank__${key}`, value: '' }
            });
          })
        }

        dispatch({
          type: FIELD_FORM_CHANGE,
          payload: { name, value },
        });
      };
    },
    [dispatch],
  );

  const resetTransferForm = useCallback(() => {
    resetDataInitialScreenTransaction()
    dispatch({
      type: RESET_TRANSFER_FORM,
      payload: { rules: transferRules.rules },
    });
  }, [transferRules]);

  const bonusCouponAmount = useCallback(() => {
    dispatch({
      type: OPERATION_WITH_COUPON,
      payload: {
        prices,
        default_currency: user.default_currency,
      },
    });
  }, [dispatch, prices, user]);

  const createTransfer = useCallback(
    async (source, pin, coupon_id, favoriteId) => {
      if (state.coupon) {
        if (parseInt(state.coupon.minAmount) <= state.amount) {
          if (state.coupon.typeCoupon === 'bonus') bonusCouponAmount();
        } else {
          coupon_id = null;
        }
      }

      const {
        form: { values },
        amount,
        sendCurrency,
        country,
        country_flag,
        receiveCurrency,
        receiveAmount,
        paymentType,
      } = state;

      const payload = {
        account_type_bank: 'n',
        document_type: 'n',
        bank_id: null,
        ...values,
        amount,
        source,
        currency: sendCurrency,
        country_flag,
        dest_currency: receiveCurrency,
        dest_country: country,
        dest_amount: receiveAmount,
        otp: pin,
        coupon_id,
        favorite_id: favoriteId,
        payment_type_id: paymentType && paymentType.id,
      };

      try {
        const response = await transactionsService.createTransfer(
          headers,
          payload,
        );
        await updateProfile();
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    },
    [state, headers, updateProfile],
  );

  const reset = useCallback(() => {
    dispatch({
      type: RESET_FORM,
      payload: {
        amount: 0,
        amountLabel: formatMoney(0, user.handle_default_fiat_currency),
        receiveAmount: 0,
        receiveAmountLabel: formatMoney(0, currency_code),
        isReady: false,
        receiveCurrency: currency_code,
        country: iso_code.toLowerCase(),
        country_flag: flag_url,
        country_currency_name: currency_name,
        rule: [],
        sendCurrency: user.handle_default_fiat_currency.toUpperCase(),
        minimumAmount: decimalAmounts(transfer_min_amount, iso_code, true),
        isValid: false,
        form: {},
        isRules: false,
        day: '',
        messageEstimatedTime: '',
      },
    });
  }, [user]);

  const validateForm = useCallback((keys_ignore) => {
    dispatch({
      type: VALIDATE_FORM,
      payload: {
        keys_ignore
      }
    });
  }, [dispatch]);

  const isValid = useMemo(() => {
    return state.isValid && !errorFetch;
  }, [
    state.isValid,
    errorFetch
  ]);

  const setConfirmation = useCallback((status) => {
    dispatch({
      type: CONFIRMATION,
      payload: {
        status,
        prices,
      }
    });
  }, [prices]);

  const handleOnChangePaymentType = useCallback((id, amount) => {
    dispatch({
      type: CHECK_PAYMENT_TYPE,
      payload: {
        id,
      },
    });
    dispatch({
      type: AMOUNT_CHANGE,
      payload: {
        amount,
        prices,
        balances,
      },
    });
  }, [dispatch, prices, user]);

  /*
  *   The name of the document number is returned when the type of
  *   beneficiary is Corporate, since the type of document does not exist
  */
  const documentLabel = useMemo(() => {
    const { form } = state;

    if (form['values'] && form['documents']) {
      const { values: { beneficiary_type = '', document_type = '' }, documents } = form;
      return documents[beneficiary_type] || document_type;
    }

    return '';
  }, [
    state.form['values'] && state.form['values']['beneficiary_type'],
    state.form['values'] && state.form['values']['document_type'],
    state.form['documents']
  ]);

  return {
    ...state,
    fiatCurrencies,
    documentLabel,
    resetTransferForm,
    changeAmount,
    changeReceiveAmount,
    changeSendCurrency,
    changeCountry,
    handleMaxAmount,
    setConfirmation,
    handleChange,
    createTransfer,
    isReadyPrices,
    reset,
    isValid,
    validateForm,
    errorFetchMessage: errorFetch,
    rules: transferRules.rules,
    loadingDataInitialScreenTransaction,
    resetDataInitialScreenTransaction,
    checkFlagScreenTransaction,
    // setDataCoupon,
    // dataCoupon,
    // cancelCouponAction,
    // onPressActive,
    // onPressInvalid,
    // hook,
    // flag,
    // setFlag,
    // clearDataCoupon,
    bonusCouponAmount,
    handleOnChangePaymentType,
  };
};

const createSchema = (country, rules, isAddFrequentAccount = false) => {
  const rule = rules[country.toLowerCase()];
  const values = {};
  const validationSchema = {};
  const touched = {};
  const documents = {};

  rule.rules.forEach((section) => {
    let fields =
      section.label === 'Información del envío' && isAddFrequentAccount
        ? []
        : section.fields;

    fields.forEach((field) => {
      values[field.name] = '';
      touched[field.name] = false;
      validationSchema[field.name] = yup.string();

      /*
      *   The names of the document number are saved according 
      *   to the visibility of the field
      */
      if (field.visible && field.visible.value.toLowerCase() === 'corporate' && field.name === 'document_number') {
        documents[field.visible.value] = field.label;
      }

      if (field.name === 'phone') {
        validationSchema[field.name] = validationSchema[field.name].matches(
          /^[0-9+]+$/,
          'Solo números',
        );
      }

      if (field.type === 'email') {
        validationSchema[field.name] = validationSchema[field.name].email('Email invalido');
      } else if (field.type !== 'select') {
        validationSchema[field.name] = validationSchema[field.name].matches(
          /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9\s]+$/,
          'No puede contener caracteres especiales',
        );
      }

      if (field.type === 'numeric' || field.label === 'CBU / CVU') {
        validationSchema[field.name] = validationSchema[field.name].matches(
          /^[0-9]+$/,
          'Solo números',
        );
      }

      if (field.min) {
        validationSchema[field.name] = validationSchema[field.name].min(
          field.min,
          `Debes escribir mínimo ${field.min} ${field.type === 'text' ? 'caracteres' : 'digitos'
          }`,
        );
      }

      if (field.max) {
        validationSchema[field.name] = validationSchema[field.name].max(
          field.max,
          `Debes escribir máximo ${field.max} ${field.type === 'text' ? 'caracteres' : 'digitos'
          }`,
        );
      }

      if (field.regex_validate) {
        validationSchema[field.name] = validationSchema[field.name].matches(field.regex_validate, field.regex_message)
      }

      if (field.length) {
        validationSchema[field.name] = validationSchema[field.name].length(
          field.length,
          `${field.label} de ${field.length} digitos`,
        );
      }

      if (field.type === 'select') {
        values[field.name] = field.options[0].label;
        values[field.name_key] = field.options[0].value;
        touched[field.name_key] = false;
        validationSchema[field.name_key] = yup.string();
      }

      if (isAddFrequentAccount) {
        if (field.isFrequentAccountField) {
          if (field.visible) {
            let duplicateField = fields.filter((item) => (item.name === field.name && item !== field))
            duplicateField = duplicateField[0] ? duplicateField[0] : null
            if (!duplicateField) {
              validationSchema[field.name] = validationSchema[field.name].when(
                field.visible.key,
                {
                  is: field.visible.value,
                  then: validationSchema[field.name].required(
                    `${field.label} requerido`,
                  ),
                  otherwise: validationSchema[field.name].nullable().transform((o, c) => o === "" ? null : c),
                },
              );
            } else {
              validationSchema[field.name] = validationSchema[field.name].when(
                field.visible.key,
                {
                  is: field.visible.value,
                  then: validationSchema[field.name].required(
                    `${field.label} requerido`,
                  ),
                  otherwise: validationSchema[duplicateField.name].when(
                    duplicateField.visible.key,
                    {
                      is: duplicateField.visible.value,
                      then: validationSchema[duplicateField.name].required(
                        `${duplicateField.label} requerido`,
                      ),
                      otherwise: validationSchema[duplicateField.name].nullable().transform((o, c) => o === "" ? null : c),
                    }
                  ),
                },
              );
            }
          } else {
            validationSchema[field.name] = validationSchema[
              field.name
            ].required(`${field.label} requerido`);
          }
        }
      } else {
        if (field.visible) {
          let duplicateField = fields.filter((item) => (item.name === field.name && item !== field))
          duplicateField = duplicateField[0] ? duplicateField[0] : null
          if (!duplicateField) {
            validationSchema[field.name] = validationSchema[field.name].when(
              field.visible.key,
              {
                is: field.visible.value,
                then: validationSchema[field.name].required(
                  `${field.label} requerido`,
                ),
                otherwise: validationSchema[field.name].nullable().transform((o, c) => o === "" ? null : c),
              },
            );
          } else {
            validationSchema[field.name] = validationSchema[field.name].when(
              field.visible.key,
              {
                is: field.visible.value,
                then: validationSchema[field.name].required(
                  `${field.label} requerido`,
                ),
                otherwise: validationSchema[duplicateField.name].when(
                  duplicateField.visible.key,
                  {
                    is: duplicateField.visible.value,
                    then: validationSchema[duplicateField.name].required(
                      `${duplicateField.label} requerido`,
                    ),
                    otherwise: validationSchema[duplicateField.name].nullable().transform((o, c) => o === "" ? null : c),
                  }
                ),
              },
            );
          }

        } else {
          validationSchema[field.name] = validationSchema[field.name].required(
            `${field.label} requerido`,
          );
        }
      }

      validationSchema[field.name] = validationSchema[field.name].matches(
        /^\S.*\S$/,
        'No puede comenzar ni terminar con espacios en blanco',
      );
    });
  });

  return {
    values,
    touched,
    validationSchema,
    validationSchemaGlobal: yup.object().shape(validationSchema),
    errors: {},
    isValidForm: false,
    documents,
  };
};

export default useTransfer;
